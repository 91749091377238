<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <div class="pageTitle">{{ isEdit ? "编辑" : "添加" }}停车卡</div>
      <!--搜索条件区域-->
      <div class="Wrapper">
        <div class="section-title">基础信息</div>
        <el-form
          label-position="right"
          label-width="120px"
          :model="formInline"
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.Parking_card_name')" prop="name">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.name"
              placeholder="请输入内容"
              style="width: 350px"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="生效日期" required style="width: 350px">
            <template>
              <el-radio v-model="formInline.effectType" :label="0">
                <el-date-picker
                  v-model="timeRange"
                  type="datetimerange"
                  range-separator="至"
                  :picker-options="pickerOptions"
                  start-placeholder="生效开始日期"
                  end-placeholder="生效结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-radio>
              <el-radio
                v-model="formInline.effectType"
                :label="1"
                style="margin-top: 20px"
              >
                自购买后
                <el-input
                  v-model="formInline.effectDaysAfterBuy"
                  :maxlength="3"
                  style="width: 100px"
                  oninput="value=value.replace(/[^\d]/g,'')"
                />
                天开始生效
              </el-radio>
            </template>
          </el-form-item>
          <el-form-item
            label="有效天数"
            prop="validityPeriod"
            class="oRelative"
            v-if="formInline.effectType === 1"
          >
            <el-input
              :disabled="isDisabled"
              :maxlength="100"
              v-model.trim="formInline.validityPeriod"
              placeholder="请输入内容"
              style="width: 350px"
            ></el-input>
            <span>天</span>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operatorId" class="is-required">
            <el-select
              v-model.trim="formInline.operatorId"
              filterable
              size="15"
              @change="operationIdChange"
            >
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用范围" prop="scope" style="width: 810px">
            <!-- <div
              v-show="isDisabled"
              style="width: 100%; height: 155px; position: absolute; z-index: 2"
            ></div> -->
            <!-- <div
              v-show="isDisabled"
              style="
                width: 476px;
                height: 208px;
                position: absolute;
                top: 155px;
                z-index: 2;
              "
            ></div> -->
            <el-select v-model="formInline.scope" placeholder="请选择">
              <el-option label="全部车场" :value="1"></el-option>
              <el-option label="部分车场" :value="2"></el-option>
            </el-select>
            <span class="inputTip">*仅限商户下车场</span>
            <el-form-item prop="formParkIds">
              <el-transfer
                v-if="formInline.scope == '2'"
                filterable
                :props="{
                  key: 'parkId',
                  label: 'parkName',
                }"
                @change="selectParkChange"
                filter-placeholder="请输入车场名称"
                :titles="['未选', '已选']"
                v-model="formInline.formParkIds"
                :data="allParkData"
                style="margin-top: 20px"
              >
              </el-transfer>
            </el-form-item>
          </el-form-item>
          <el-form-item label="泊位车辆限制" class="is-required">
            <div style="display: flex">
              <el-form-item prop="berthMaxNum" style="margin-right: 10px" ref="carMaxNumRule">
                <el-input
                  v-model="formInline.berthMaxNum"
                  style="width: 100px"
                  maxlength="8"
                  oninput="value=value.replace(/[^\d]/g,'')"
                />&nbsp;&nbsp;&nbsp;泊位&nbsp;&nbsp;&nbsp;</el-form-item
              >
              <el-form-item prop="carMaxNum" style="margin-right: 10px" ref="berthMaxNumRule">
                <el-input
                  v-model="formInline.carMaxNum"
                  maxlength="8"
                  style="width: 100px"
                  oninput="value=value.replace(/[^\d]/g,'')"
                />&nbsp;&nbsp;&nbsp; 车
                <el-tooltip placement="top" class="tooltip1" style="top: 0px; left: 10px">
                  <div slot="content">
                    泊位数表示可以同时停车的位置数量，车辆数表示与这张停车卡关联的车辆总数
                  </div>
                  <span class="help1">?</span>
                </el-tooltip></el-form-item
              >
            </div>
          </el-form-item>
          <el-form-item label="免费时间段" class="is-required">
            <free-item ref="ticketFrom" v-model="formInline" />
          </el-form-item>
          <el-form-item label="启用">
            <el-switch
              v-model="formInline.state"
              active-color="#409EFF"
              inactive-color="#909399"
              :active-value="Number(2)"
              :inactive-value="Number(4)"
            >
            </el-switch>
          </el-form-item>

          <div class="section-title">销售信息</div>
          <!-- <el-form-item label="销售日期" prop="daterange">
            <el-date-picker
              v-model="formInline.daterange"
              type="datetimerange"
              range-separator="至"
              :picker-options="pickerOptions1"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item label="停车卡数量" prop="numberType">
            <template>
              <el-radio v-model="formInline.numberType" :label="1">不限</el-radio>
              <el-radio v-model="formInline.numberType" :label="2">
                限
                <el-input v-model="formInline.quantities" maxlength="8" style="width: 100px" />
                张
              </el-radio>
              <span class="inputTip">根据合同签约的车位数量，配置停车卡数量</span>
            </template>
          </el-form-item>
          <!-- <el-form-item label="销售渠道" prop="appSale">
            <el-select :disabled="isDisabled" v-model="formInline.appSale">
              <el-option label="线上销售" :value="true"></el-option>
              <el-option label="后台录入" :value="false"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="线上销售" prop="appSale">
            <el-switch
              v-model="formInline.appSale"
              active-color="#409EFF"
              inactive-color="#909399"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item>
          <!-- <el-form-item label="原价" prop="originalPrice" class="oRelative">
            <el-input
              :disabled="isDisabled"
              :maxlength="100"
              v-model.trim="formInline.originalPrice"
              @change="computedActualAmount"
              placeholder="输入内容"
              style="width: 350px"
            ></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item
            label="优惠金额"
            prop="discountAmount"
            class="oRelative"
          >
            <el-input
              :disabled="isDisabled"
              :maxlength="100"
              v-model.trim="formInline.discountAmount"
              @change="computedActualAmount"
              placeholder="输入内容"
              style="width: 350px"
            ></el-input>
            <span>元</span>
          </el-form-item>-->
          <el-form-item label="销售价格" class="is-required">
            <div style="display: flex">
              <el-form-item prop="actualAmount">
                <el-input
                  :maxlength="100"
                  v-model.trim="formInline.actualAmount"
                  placeholder="输入内容"
                  style="width: 100px"
                  @input="computedActualAmount(formInline.actualAmount)"
                ></el-input>
              </el-form-item>
              <div style="margin-left: 30px; margin-right: 30px">元&nbsp;&nbsp;&nbsp;/</div>
              <el-select v-model="formInline.priceCycle" placeholder="请选择" style="width: 120px">
                <!-- <el-option value="2" label="每月"></el-option> -->
                <el-option
                  v-for="item in payTimeLists"
                  :key="item.payTimeRuleId"
                  :label="item.timeName"
                  :value="item.payTimeRuleId"
                />
              </el-select>
            </div>
          </el-form-item>

          <el-form-item :label="$t('searchModule.remarks')" prop="remarks">
            <el-input
              :maxlength="50"
              type="textarea"
              v-model.trim="formInline.remarks"
              placeholder="输入内容"
              style="width: 350px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left; margin-left: 8.5%">
        <el-button type="primary" @click="submitData" style="width: 88px">{{ $t('button.preservation') }}</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import freeItem from "./freeTime.vue";
// import { dateFormat } from "@/common/js/public";
export default {
  name: "parkRuleAddEdit",
  data: function () {
    let that = this;
    const actualAmountRule = (rule, value, callback) => {
      this.formInline.actualAmount = (
        (this.formInline.originalPrice * 10000 - this.formInline.discountAmount * 10000) /
        10000
      ).toFixed(2);
      if (this.formInline.actualAmount <= 0) {
        callback(new Error("原价必须大于优惠金额"));
      } else {
        callback();
      }
    };
    const originalPriceRule = (rule, value, callback) => {
      if (!/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
        callback(new Error("请输入大于等于0的数字,最多两位小数"));
      } else {
        callback();
      }
    };
    const daterangeRule = (rule, value, callback) => {
      console.log("111".value);
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      // 进行中的开始日期早于当日，不校验开始日期
      if (that.isEdit && that.queryState != 1) {
        callback();
      } else if (this.$moment(value[0]).valueOf() / 1000 >= parseInt(now.getTime() / 1000)) {
        // 添加、或编辑当前日期的规则，需校验是否大于等于当日
        callback();
      } else {
        callback(new Error("可购买日期必须大于等于当日"));
      }
    };
    const effectStartTimeRule = (rule, value, callback) => {
      if (value <= this.formInline.daterange[0]) {
        callback(new Error("生效开始日期必须大于可购买日期的开始日期"));
      } else {
        callback();
      }
    };
    const validityPeriodRule = (rule, value, callback) => {
      if (!/^-?[1-9]\d*$/.test(value) || value < 0) {
        callback(new Error("请输入大于0的整数"));
      } else if (value > 366) {
        callback(new Error("不超过366"));
      } else {
        callback();
      }
    };
    const carMaxNumRule = (rule, value, callback) => {
      if (!/^-?[1-9]\d*$/.test(value) || value <= 0) {
        callback(new Error("请输入大于0的整数"));
      } else if (Number(this.formInline.berthMaxNum) > Number(value)) {
        callback(new Error("泊位数应小于等于车辆数"));
      } else {
        if (
          Number(this.formInline.berthMaxNum) <= Number(value) &&
          this.formInline.berthMaxNum > 0 &&
          value > 0
        ) {
          console.log("899999999999999999999999");
          this.$refs["carMaxNumRule"].clearValidate();
          this.$refs["berthMaxNumRule"].clearValidate();
          callback();
        }
        callback();
      }
    };
    const berthMaxNumRule = (rule, value, callback) => {
      if (!/^-?[1-9]\d*$/.test(value) || value <= 0) {
        callback(new Error("请输入大于0的整数"));
      } else if (Number(value) > Number(this.formInline.carMaxNum)) {
        callback(new Error("泊位数应小于等于车辆数"));
      } else {
        if (
          Number(value) <= Number(this.formInline.carMaxNum) &&
          this.formInline.carMaxNum > 0 &&
          value > 0
        ) {
          console.log("899999999999999999999999---------");
          this.$refs["berthMaxNumRule"].clearValidate();
          this.$refs["carMaxNumRule"].clearValidate();
          callback();
        }
        callback();
      }
    };
    const scopeRule = (rule, value, callback) => {
      if (this.formInline.scope == 2) {
        if (value.length < 1) {
          callback(new Error("请选择车场"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      queryState: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 3600 * 1000 * 24;
        },
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 3600 * 1000 * 24;
        },
      },
      timeRange: [],
      roleList: [],
      oldBuyEndTime: 0,
      oldBuyStartTime: 0,
      isDisabled: false,
      isEdit: false,
      allParkData: [],
      scopeList: [],
      parkName: "",
      dis: false,
      lnglat: "",
      parkData: [],
      parkArr: [],
      buyTypeList: [],
      formInline: {
        name: "",
        buyStartTime: "",
        buyEndTime: "",
        daterange: [],
        effectStartTime: "",
        validityPeriod: "",
        operatorId: "",
        scope: "",
        formParkIds: [],
        originalPrice: "",
        discountAmount: "0",
        actualAmount: "",
        remarks: "",
        appSale: false,
        effectDaysAfterBuy: "",
        effectType: 0,
        numberType: 1,
        quantities: "",
        carMaxNum: "", // 车辆限位
        berthMaxNum: "", // 泊位数量
        freeTimeList: [
          {
            type: "1", // 类型：type(1每天，2工作日，3节假日，4限号日)
            allDay: 0,
            startTime: "",
            endTime: "",
          },
        ],
        freeTimeSlot: [],
        state: 1,
        priceCycle: "2",
        salePrice: "",
      },
      payTimeLists: [
        { payTimeRuleId: 1, timeName: "每天" },
        { payTimeRuleId: 2, timeName: "每月" },
        { payTimeRuleId: 3, timeName: "每季度" },
        { payTimeRuleId: 4, timeName: "每半年" },
        { payTimeRuleId: 5, timeName: "每年" },
      ],
      rules: {
        operatorId: [
          {
            required: true,
            message: "必填",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        daterange: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: daterangeRule, trigger: "change" },
        ],
        effectStartTime: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: effectStartTimeRule, trigger: "blur" },
        ],
        validityPeriod: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: validityPeriodRule, trigger: "blur" },
        ],
        formParkIds: [{ validator: scopeRule, trigger: "blur" }],
        scope: [
          {
            required: true,
            message: "必填",
            trigger: "change",
          },
        ],
        carMaxNum: [
          {
            required: true,
            message: "请填写正整数，最小为1",
            trigger: "blur change",
          },
          { validator: carMaxNumRule, trigger: "blur" },
        ],
        berthMaxNum: [
          {
            required: true,
            message: "请填写正整数，最小为1",
            trigger: "blur change",
          },
          { validator: berthMaxNumRule, trigger: "blur" },
        ],
        originalPrice: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          {
            validator: originalPriceRule,
            trigger: "blur",
          },
        ],
        discountAmount: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
          {
            validator: originalPriceRule,
            trigger: "blur",
          },
        ],
        actualAmount: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          // { validator: actualAmountRule, trigger: "change" },
        ],
        appSale: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        salePrice: [{ required: true, message: "必填", trigger: "blur change" }],
        numberType: [{ required: true, message: "必选", trigger: "blur" }],
      },
      cardType: "",
    };
  },
  computed: {
    effectStartTimeDisabled: {
      get() {
        if (!this.formInline.daterange || !this.formInline.daterange[0]) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  watch: {
    formInline: {
      handler(ne, old) {
        // console.log(ne, "111111111111");
        let timeList = JSON.parse(JSON.stringify(ne.freeTimeList));
        timeList.forEach((v, i) => {
          // if (v.allDay) {
          //   v.startTime = "";
          //   v.endTime = "";
          // }
          // v.startTime = v.value1 ? String(v.value1).slice(16, 21) : "";
          // v.endTime = v.value2 ? String(v.value2).slice(16, 21) : "";
          // v.allDay = v.disabled ? 1 : 0;
          delete v.disabled;
          delete v.value1;
          delete v.value2;
        });
        ne.freeTimeSlot = JSON.stringify(timeList);
        // console.log(ne, "2222222222222222");
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    computedActualAmount(val) {
      // console.log(val, "0909019230123");
    },
    selectParkChange(currentArr, leftOrRihght, changeArr) {
      if (currentArr.length > 20) {
        this.formInline.formParkIds = this.formInline.formParkIds.slice(0, 20);
        this.$alert("最多可选20个车场");
      }
    },
    getDownMenu() {
      this.$axios.get("/acb/2.0/parkCard/getSelectionValues").then((res) => {
        this.scopeList = res.value.scope;
        this.buyTypeList = res.value.buyType;
      });
    },
    queryDetail() {
      this.$axios
        .get("/acb/2.0/parkCardRule/detail", {
          data: {
            parkCardRuleId: this.$route.query.parkCardRuleId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let data = res.value;
            let {
              parkCardRuleId = "",
              name = "",
              buyStartTime,
              buyEndTime,
              effectStartTime,
              effectEndTime,
              validityPeriod,
              operatorId,
              scope,
              originalPrice,
              discountAmount,
              actualAmount,
              remarks,
              appSale,
              state,
              effectDaysAfterBuy,
              effectType,
              quantities,
              carMaxNum,
              berthMaxNum,
              freeTimeSlot,
              priceCycle,
            } = data;
            this.queryState = state;
            this.oldBuyStartTime = buyStartTime;
            this.oldBuyEndTime = buyEndTime;
            this.formInline.name = name;
            this.formInline.parkCardRuleId = parkCardRuleId;
            this.formInline.buyStartTime = buyStartTime;
            this.formInline.buyEndTime = buyEndTime;
            // this.formInline.daterange = [buyStartTime, buyEndTime]
            this.formInline.validityPeriod = validityPeriod;
            this.formInline.operatorId = operatorId;
            this.formInline.priceCycle = priceCycle;
            this.formInline.scope = scope;
            this.formInline.freeTimeList = JSON.parse(freeTimeSlot);
            let _date = new Date();
            this.formInline.freeTimeList.forEach((item) => {
              item.disabled = Boolean(item.allDay);
              if (item.allDay == 0) {
                item.value1 = new Date(
                  _date.getFullYear(),
                  _date.getMonth(),
                  _date.getDate(),
                  item.startTime.split(":")[0],
                  item.startTime.split(":")[1]
                );
                item.value2 = new Date(
                  _date.getFullYear(),
                  _date.getMonth(),
                  _date.getDate(),
                  item.endTime.split(":")[0],
                  item.endTime.split(":")[1]
                );
              }
            });
            // console.log(
            //   this.formInline.freeTimeList,
            //   "this.formInline.freeTimeList"
            // );
            this.formInline.remarks = remarks;
            this.formInline.appSale = appSale;
            this.formInline.state = state;
            this.formInline.discountAmount = discountAmount ? discountAmount / 100 : 0;
            this.formInline.originalPrice = originalPrice ? originalPrice / 100 : 0;
            this.formInline.actualAmount = actualAmount ? actualAmount / 100 : 0;
            if (this.cardType != "again") {
              this.timeRange = [
                this.$moment(parseInt(effectStartTime)).format("YYYY-MM-DD HH:mm:ss"),
                this.$moment(parseInt(effectEndTime)).format("YYYY-MM-DD HH:mm:ss"),
              ];
              this.formInline.daterange = [
                this.$moment(parseInt(buyStartTime)).format("YYYY-MM-DD HH:mm:ss"),
                this.$moment(parseInt(buyEndTime)).format("YYYY-MM-DD HH:mm:ss"),
              ];
            }
            this.formInline.effectStartTime = effectStartTime;
            this.formInline.effectDaysAfterBuy = effectDaysAfterBuy;
            this.formInline.effectType = effectType;
            this.formInline.quantities = quantities;
            this.formInline.carMaxNum = carMaxNum;
            this.formInline.berthMaxNum = berthMaxNum;
            if (quantities === 0) {
              this.formInline.numberType = 1;
              this.formInline.quantities = "";
            } else {
              this.formInline.numberType = 2;
            }
            // 获取已选择车场
            if (data.parks && data.parks.length > 0 && scope == 2) {
              let selectParks = [];
              data.parks.forEach((v) => {
                selectParks.push(v.parkId);
              });
              this.formInline.formParkIds = selectParks;
            }
            // 是否可编辑
            if (data.state != 0 && this.cardType != "again") {
              this.isDisabled = true;
            }
          } else {
            this.$alert(res.desc);
          }
        });
    },
    submitData() {
      // let list1 = JSON.parse(JSON.stringify(this.formInline.freeTimeList));
      // console.log(this.formInline.freeTimeList, "6666666666");
      // console.log(this.formInline.freeTimeSlot, "77777777777");
      // console.log(list1, "888888888888");
      // // let list1 = JSON.parse(this.formInline.freeTimeSlot);
      // // 如果没有选时间段和全天
      // list1.forEach((v) => {
      //   console.log(v.allDay, v.startTime, v.endTime, "123");
      //   if (v.allDay == 0 && (!v.startTime || !v.endTime)) {
      //     this.$alert("请选择时间段");
      //     return 0;
      //   }
      // });
      this.formInline.parkIds = this.formInline.formParkIds.toString();
      if (this.formInline.effectType === 0 && this.timeRange.length > 0) {
        this.formInline.effectStartTime = this.$moment(this.timeRange[0]).valueOf();
        this.formInline.effectEndTime = this.$moment(this.timeRange[1]).valueOf();
        this.formInline.effectDaysAfterBuy = "";
        this.formInline.validityPeriod = "";
      }
      if (this.formInline.effectType === 1 && this.formInline.effectDaysAfterBuy === "") {
        this.$alert("请正确填写购买后几天开始生效");
        return;
      } else if (this.formInline.effectType === 1 && this.formInline.effectDaysAfterBuy !== "") {
        if (!/^[0-9]\d*$/.test(this.formInline.effectDaysAfterBuy)) {
          this.$alert("自购买后X天开始生效，X为整数");
          return;
        }
        if (this.formInline.effectDaysAfterBuy < 0 || this.formInline.effectDaysAfterBuy > 365) {
          this.$alert("自购买后X天开始生效，X范围0-365");
          return;
        }
        if (this.formInline.validityPeriod < 1 || this.formInline.validityPeriod > 365) {
          this.$alert("有效天数Y，Y范围1-365");
          return;
        }
        this.timeRange = [];
        this.formInline.effectStartTime = "";
        this.formInline.effectEndTime = "";
      }

      this.formInline.buyStartTime = this.$moment(this.formInline.daterange[0]).valueOf();
      this.formInline.buyEndTime = this.$moment(this.formInline.daterange[1]).valueOf();

      if (this.formInline.numberType === 2 && !/^[1-9]\d*$/.test(this.formInline.quantities)) {
        this.$alert("停车卡数量限X张，X应为正整数");
        return;
      }
      let url =
        this.isEdit && !this.cardType
          ? "/acb/2.0/parkCardRule/update"
          : "/acb/2.0/parkCardRule/add";
      let method = this.isEdit && !this.cardType ? "post" : "post";
      let data = {};

      let copForminline = Object.assign({}, this.formInline);
      copForminline.originalPrice = Math.round(copForminline.originalPrice * 100);
      copForminline.discountAmount = Math.round(copForminline.discountAmount * 100);
      copForminline.actualAmount = Math.round(copForminline.actualAmount * 100);
      delete copForminline.daterange;
      delete copForminline.formParkIds;
      // 处理停车卡数量
      if (copForminline.numberType === 1) {
        copForminline.quantities = 0;
      }
      if (this.isEdit) {
        data = {
          parkCardRuleId: this.$route.query.parkCardRuleId,
          ...copForminline,
        };
      } else {
        data = copForminline;
      }

      // 处理免费时间段传参规则
      console.log(data.freeTimeList, "data.freeTimeList");
      console.log(data.freeTimeSlot, "data.freeTimeSlot");

      // data.freeTimeList.forEach((v, i) => {
      //   v.startTime = v.value1 ? String(v.value1).slice(16, 21) : "";
      //   v.endTime = v.value2 ? String(v.value2).slice(16, 21) : "";
      //   v.allDay = v.disabled ? 1 : 0;
      //   delete v.disabled;
      //   delete v.value1;
      //   delete v.value2;
      // });
      // data.freeTimeSlot = JSON.stringify(data.freeTimeList);
      // delete data.freeTimeList;

      // 判断是否选择时间段
      let list1 = JSON.parse(data.freeTimeSlot);
      console.log(list1, "list1");
      let resList = true;
      list1.forEach((v) => {
        if (!("allDay" in v) && (!v.startTime || !v.endTime)) {
          this.$alert("请选择时间段");
          resList = false;
        }
        if ((!v.startTime || !v.endTime) && v.allDay == 0) {
          this.$alert("请选择时间段");
          resList = false;
        }
      });
      if (!resList) return;
      console.log(data.freeTimeSlot, "data.freeTimeSlot");
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(data, "0-0-0-0-0-0-0-0-0--0-0-");
          console.log(JSON.parse(data.freeTimeSlot), "99999999999");
          this.$axios[method](url, {
            data: data,
            Headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            if (res.state == 0) {
              this.$message({
                message: "操作成功！",
                type: "success",
              });
              setTimeout((_) => {
                this.$router.go(-1);
              }, 500);
            } else {
              this.$alert(res.desc);
            }
          });
        }
      });
    },
    operationIdChange(value) {
      this.formInline.formParkIds = [];
      this.getParkAllList(value);
    },
    getParkAllList(operationId) {
      this.$axios("/acb/2.0/park/currentUserParkList", {
        data: {
          operationId: operationId,
          dataSource: 0,
        },
      }).then((res) => {
        this.allParkData = res.value;
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.roleList = res.value;
        }
      });
    },
  },
  mounted() {
    this.cardType = this.$route.query.cardType || "";
    this.getRoleList();
    if (this.$route.query.parkCardRuleId) {
      this.getParkAllList(this.$route.query.operatorId);
      this.isEdit = true;
      this.queryDetail();
    }
  },
  created() {
    this.formInline.priceCycle = this.payTimeLists[1].payTimeRuleId;
  },
  components: {
    freeItem,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper {
  overflow: hidden;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  padding: 20px;

  .pageTitle {
    line-height: 30px;
    font-size: 16px;
  }

  .inputTip {
    margin-left: 20px;
    color: #999;
  }

  .oRelative {
    position: relative;

    span {
      position: absolute;
      right: -20px;
      top: 0;
    }
  }
}

.section-title {
  line-height: 35px;
  background-color: #ccc;
  color: #656565;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 20px;
}

.tooltip1 {
  // position: absolute !important;
  z-index: 1;
}

.help1 {
  display: inline-block;
  border-radius: 8px;
  text-align: center;
  color: white;
  width: 16px;
  line-height: 16px;
  vertical-align: middle;
  background: #c0ccda !important;
  position: relative;
}
</style>
