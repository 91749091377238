<template>
  <el-form ref="form" :model="formInline">
    <el-form-item
      :class="{ marginSty: ind !== formInline.freeTimeList.length - 1 }"
      label=""
      :prop="'freeTimeList[' + ind + '][type]'"
      :rules="[
        { required: true, message: '请选择免费时间段', trigger: 'change' },
      ]"
      :key="ind"
      v-for="(val, ind) in formInline.freeTimeList"
    >
      <el-select v-model="val.type" placeholder="请选择" style="width: 120px">
        <el-option value="1" label="每天"></el-option>
        <el-option
          v-for="item in timeLists"
          :key="item.type"
          :label="item.timeName"
          :value="item.type"
        />
      </el-select>
      <!-- <el-time-picker
        is-range
        v-model="val.timePick"
        range-separator="~"
        value-format="HH:mm"
        format="HH:mm"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        style="margin-left: 10px"
        :disabled="val.disabled"
      >
      </el-time-picker> -->
      <el-time-picker
        v-model="val.startTime"
        placeholder="开始时间"
        style="margin: 0 20px"
        format="HH:mm"
        value-format="HH:mm"
        :disabled="Boolean(val.allDay)"
      >
      </el-time-picker>
      <el-time-picker
        v-model="val.endTime"
        placeholder="结束时间"
        format="HH:mm"
        value-format="HH:mm"
        :disabled="Boolean(val.allDay)"
      >
      </el-time-picker>
      <!-- @change="
          console.log(val);
          val.allDay = Number(!val.allDay);
          val.disabled = !val.disabled;
        " -->
      <el-checkbox
        :value="Boolean(val.allDay)"
        class="checkedfalse"
        @change="change1(val, val.disabled, ind)"
        >全天</el-checkbox
      >
      <span id="addTimeList" v-if="ind == 0" @click="addTimeList">+</span
      ><span v-else id="addTimeList" @click="deleteTimeList(ind)">-</span>
    </el-form-item>
  </el-form>
</template>
  <script>
export default {
  props: ["value"],
  data() {
    return {
      timeLists: [
        // { type: 1, timeName: "每天" },
        { type: 2, timeName: "工作日" },
        { type: 3, timeName: "节假日" },
        { type: 4, timeName: "限号日" },
      ],
      formInline: { freeTimeList: [] },
    };
  },
  watch: {
    value: {
      handler(ne, old) {
        // console.log(ne, old, "ne");
        this.formInline = ne;
        // console.log("456");
        // console.log(this.formInline.freeTimeList, "this.formInline");
      },
      immediate: true,
      deep: true,
    },
    formInline: {
      handler(ne, old) {
        this.$emit("input", ne);
        // console.log(this.formInline, "this.formInline0000000000");
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    change1(val, disabled, index) {
      console.log(val, index, disabled, "val");
      //
      val.disabled = !val.disabled;
      val.allDay = Number(!val.allDay);
      this.$set(this.formInline.freeTimeList, index, val);
    },
    onRadioChange(e) {
      console.log(e.target, "e.target");
    },
    deleteTimeList(ind) {
      let freeTimeList = this.formInline.freeTimeList;
      freeTimeList.splice(ind, 1);
    },
    checkChange(e, val, ind) {
      let obj = this.formInline.freeTimeList[ind];
      console.log(obj, val, "obj");
      obj.disabled = !val;

      // 使用 this.$set 来更新数组中的对象
      this.$set(this.formInline.freeTimeList, ind, obj);
      console.log(this.formInline.freeTimeList[ind], "123");
    },
    addTimeList() {
      if (this.formInline.freeTimeList.length >= 10) {
        this.$alert("最多添加10条！", this.$t('pop_up.Tips'), {
          type: "warning",
        });
        return;
      }
      this.formInline.freeTimeList.push({
        type: "",
        startTime: "",
        endTime: "",
        allDay: 0,
        disabled: 0,
      });
    },
    validate() {
      let flag = true;
      this.$refs.form.validate((valid) => {
        flag = valid;
      });
      return flag;
    },
    timeChange($event, val, ind) {
      console.log($event, val, ind, "timeChange");
    },
  },
  mounted() {},
};
</script>
<style scoped lang="stylus">
.marginSty {
  margin-bottom: 20px;
}

#addTimeList {
  cursor: pointer;
  font-size: 40px;
  color: #409EFF;
  display: inline-block;
  vertical-align: top;
  margin-top: -3px;
  width: 40px;
  text-align: center;
  margin-left: 50px;
}

.checkedfalse {
  margin-left: 20px;
}

/deep/ .checkedfalse .el-checkbox__input .el-checkbox__inner {
  // background: #409EFF;
  border: 1px solid #DCDFE5;
  border-radius: 50%; // 对边框进行调整
}

/deep/ .checkedfalse .el-checkbox__input .el-checkbox__inner::after {
  border-color: #ffffff; // 里面内容的颜色
  border-width: 2px; // 对里面的对号做一个加粗
}
</style>

